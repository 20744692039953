import React from "react"
import { Routes } from "../../data/routes"
import Card from "../../components/card"
import { ClosingCTA } from "../../components/CTA"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { SecondaryButton } from "../../components/button"
import Img from "../../components/img"

const Content = {
  pageName: "Features",
  smallTitle: "How Yup Works",
  description: `We combine secure 1:1 messaging with a whiteboard, enabling
    students and tutors to solve problems together in a private virtual classroom.`,
  header: `Yup supports the learning already happening in classrooms`,
  headerDescriptions: [
    {
      bold: "For Students",
      plain: `Yup means 24/7 one-on-one tutoring and on-demand access to a
        supportive learning environment.`,
    },
    {
      bold: "For Teachers",
      plain: `Yup is a tool to support every student and get data-driven insights
        on student learning.`,
    },
  ],
  headerAction: "See Yup in Action!",
  headerLink: "https://vimeo.com/434145335",
  headerImage: "features-cover.png",

  // Steps section
  steps: [
    {
      video: "/media/yup-step1.mp4",
      step: "Student submits a math problem",
      description: `Students upload a math problem by taking a photo or using the
        screen capture tool.`,
      link: Routes.mathTutoring,
      action: "Subjects We Teach",
    },
    {
      video: "/media/yup-step2.mp4",
      step: "Student connects to a live tutor in seconds",
      description: `Our Smart Matching technology instantly pairs students with
        the best tutor for them and their problem. Smart Matching takes into
        consideration tutor subject area expertise and the student's previous
        experience with specific tutors.`,
      link: Routes.ourTutors,
      action: "Meet our tutors",
    },
    {
      video: "/media/yup-step3.mp4",
      step: "Student and tutor start solving together",
      description: `Yup enables students and tutors to communicate securely and
        solve problems collaboratively in their own virtual classroom. They use
        a number of tools to aid instruction including a digital whiteboard,
        equation writing, graphical interfaces, and image sharing.`,
      link: Routes.learningFundamentals,
      action: "How we teach",
    },
    {
      video: "/media/yup-teacher-dashboard.mp4",
      step: "Teacher has all session data on Yup Dashboard",
      description: `Yup provides full transparency into every student-tutor
        interaction on our platform. Teachers can see usage metrics, access
        transcripts, and review personalized tutor summaries for each session.`,
      link: Routes.learningFundamentals,
      action: "How we teach",
    },
  ],

  // Mid-page CTA
  midPageCTA: "Ready to learn how Yup can help?",
  midPageCTA2: "",
  midPageCTAActions: [
    {
      link: Routes.schools,
      text: "Schedule Demo",
    },
  ],

  // Features section
  featuresHeader: "Learn how Yup's technology powers our tutoring",
  features: [
    {
      image: "features-1.png",
      feature: "Match with Your Favorite Tutors",
      description: `Students can add tutors
        to their own list of Favorite Tutors. If these tutors are available
        when students request help, Yup’s Smart Matching prioritizes matching
        them together.`,
    },
    {
      image: "features-2.png",
      feature: "Build Connection",
      description: `Students can get to know their tutors by reading curated
        tutor profiles. They can learn about tutor career aspirations, reasons
        they love math, and fun facts such as their favorite foods. This helps
        students build rapport before diving into the math.`,
    },
    {
      image: "features-3.png",
      feature: "Revisit Past Sessions",
      description: `Students can always go back and view sessions at a later
        date in order to brush up on a concept or study for an exam.`,
    },
    {
      image: "icon-exchange.svg",
      feature: "Learn on Any Device",
      description: `Students can work with Yup tutors on a laptop via web
        browser or mobile app. This provides multiple points of access and
        puts the power of Yup on every students' favorite device.`,
    },
  ],

  // Closing CTA
  closingCTA: "Ready to learn how Yup can help?",
  closingCTA2: "",
  closingCTAActions: [
    {
      link: Routes.schools,
      text: "Schedule Demo",
    },
  ],
}

function Header() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/heroes#component-a3c09c7a3b8f4bd90d280722eaff9634
  return (
    <div className="flex flex-col-reverse text-center md:text-left md:flex-row justify-around items-center mx-auto max-w-7xl">
      <div className="p-8">
        <h1 className="">
          <span className="text-base font-semibold text-secondary tracking-wide uppercase">
            {Content.smallTitle}
          </span>
          <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
            <span className="text-gray-900">{Content.header}</span>
          </span>
        </h1>
        {Content.headerDescriptions.map((description, index) => (
          <p
            className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0"
            key={index}
          >
            <strong className="text-gray-900 mr-2">{description.bold}</strong>
            {description.plain}
          </p>
        ))}
      </div>
      <div className="p-4 flex-grow w-full">
        <Img
          className="sm:max-w-xl md:max-w-5xl lg:max-w-7xl w-full max-h-96"
          src={Content.headerImage}
          alt={Content.header}
        />
      </div>
    </div>
  )
}

type VideoProps = {
  video: string
  step: number
  title: string
  description: string
  link: string
  action: string
}

function VideoSection(props: VideoProps) {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/cta-sections#component-58b6441d042e26470c2d485039ead146
  return (
    <Card>
      <div className="flex flex-col md:flex-row items-center">
        <video
          autoPlay={true}
          loop={true}
          className="h-56 sm:h-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl my-2"
          playsInline={true}
          muted={true}
        >
          <source src={props.video} type="video/mp4" />
        </video>
        <div className="py-12 sm:px-6 lg:px-8 lg:py-16">
          <div className="md:ml-auto md:pl-10 text-center md:text-left">
            <div className="mx-auto md:mx-0 text-5xl font-extrabold rounded-full mb-4 bg-secondary text-white w-16 h-16 flex justify-center items-center">
              {props.step}
            </div>
            <p className="mt-4 text-3xl font-extrabold tracking-tight sm:text-4xl">
              {props.title}
            </p>
            <p className="mt-3 text-lg text-gray-300">{props.description}</p>
            <div className="mt-8">
              <SecondaryButton href={props.link} text={props.action} />
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}

function FeaturesSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/cta-sections#component-58b6441d042e26470c2d485039ead146
  return (
    <Card>
      <div className="px-4 py-8 sm:px-6 lg:px-8">
        <div className="text-center">
          <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            {Content.featuresHeader}
          </p>
        </div>
      </div>
      {Content.features.map((feature, index) => (
        <div
          key={feature.feature}
          className={`flex flex-col items-center my-8 ${
            index % 2 ? "md:flex-row-reverse" : "md:flex-row"
          }`}
        >
          <div className="w-full md:w-1/2 p-8">
            <Img
              src={feature.image}
              alt={feature.feature}
              className="rounded-3xl h-full w-full max-h-72"
            />
          </div>
          <div className="w-full md:w-1/2 px-8 text-center md:text-left">
            <p className="mt-2 text-gray-900 text-3xl font-extrabold tracking-tight sm:text-4xl">
              {feature.feature}
            </p>
            <p className="mt-3 text-lg text-gray-500">{feature.description}</p>
          </div>
        </div>
      ))}
    </Card>
  )
}

const Features = () => {
  return (
    <Layout>
      <Seo
        title={Content.pageName}
        description={Content.description}
        route={Routes.features}
      />
      <Header />
      {Content.steps.map((step, i) => (
        <VideoSection
          key={step.step}
          video={step.video}
          step={i + 1}
          title={step.step}
          description={step.description}
          link={step.link}
          action={step.action}
        />
      ))}
      <FeaturesSection />
      <ClosingCTA
        title={Content.closingCTA}
        title2={Content.closingCTA2}
        actions={Content.closingCTAActions}
      />
    </Layout>
  )
}

export default Features
